const filters = [
    {
        key: "from",
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
        day: "numeric",
        },
        cols: 6,
        nameFilter: 'from',
        visible: true
    },
    {
        key: "to",
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        nameFilter: 'to',
        visible: true
    },
   
    {
        key: "department",
        type: "select",
        margin: true,
        showLabel: true,
        label: "Department",
        model: null,
        options: [
            { id: 7, text: "Boost Credit" },
            { id: 20, text: "Connection"},
            { id: 6, text: "Customer Service Regular" },
            { id: 22, text: "Customer Service Digital" },
            { id: 5, text: "Debt Solution" }
        ],
        reduce: "id",
        selectText: "text",
        cols: 12,
        visible: true,
    },
    {
        key: "type",
        type: "select",
        margin: true,
        showLabel: true,
        label: "Type",
        model: null,
        options: [
            { id: 151, text: "Credit line increase" },
            { id: 100, text: "Freeze" },
            { id: 150, text: "Research" },
            { id: 152, text: "SSN variations" },
            { id: 149, text: "Unfrezee"},
        ],
        reduce: "id",
        selectText: "text",
        visible: true
    },
    {
        key: "status",
        type: "select",
        margin: true,
        showLabel: true,
        label: "Status",
        model: null,
        options: [
            { id: 1, text: 'PENDING' },
            { id: 2, text: 'ACCEPTED' },
            { id: 3, text: 'REJECTED' }
        ],
        reduce: "id",
        selectText: "text",
        visible: true
    }
];


export default filters;
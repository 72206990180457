var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.FiltersForSlot.filters,"total-rows":_vm.FiltersForSlot.totalRows,"paginate":_vm.FiltersForSlot.paginate,"start-page":_vm.FiltersForSlot.startPage,"to-page":_vm.FiltersForSlot.toPage,"filter-principal":_vm.FiltersForSlot.filterPrincipal},on:{"reload":function($event){return _vm.$refs['AppointmentTable'].refresh()},"reset-all-filters":_vm.resetAllFilters},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"AppointmentTable",staticClass:"blue-scrollbar",attrs:{"slot":"table","items":_vm.myProvider,"fields":_vm.visibleFields,"busy":_vm.isBusy,"show-empty":"","no-provider-filtering":"","per-page":_vm.FiltersForSlot.paginate.perPage,"current-page":_vm.FiltersForSlot.paginate.currentPage,"foot-clone":false},on:{"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(responsible)",fn:function(data){return [_c('center',[_c('b-badge',{class:_vm.StatusColorBackground(data.value),attrs:{"id":"span_status","pill":""}},[(data.item.responsible == 1 && _vm.moduleId == 25)?_c('span',[_vm._v("IN AGENT")]):_c('span',[_vm._v(" "+_vm._s(data.item.responsible)+" ")])])],1)]}},{key:"cell(minimum_amount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.minimum_price))+" ")]}},{key:"cell(paid)",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
              'text-success': item.status_paid == 'YES',
              'text-warning': item.status_paid == 'PENDING',
              'text-danger': item.status_paid == 'NO',
            }},[_vm._v(" "+_vm._s(_vm.statusPaid(item))+" ")])]}},{key:"cell(files)",fn:function(data){return [_c('div',{staticClass:"position-relative"},[_c('feather-icon',{staticClass:"cursor-pointer",class:{
                'text-secondary': data.item.status_paid != 'YES',
                'text-primary':
                  data.item.status_paid == 'YES' &&
                  data.item.files_counter > 0,
              },style:({
                'pointer-events':
                  data.item.status_paid == 'YES' ? 'auto' : 'none',
              }),attrs:{"icon":"PaperclipIcon","size":"20"},on:{"click":function($event){data.item.status_paid == 'YES' &&
                  _vm.openVerificationModal(data.item)}}}),(data.item.files_counter)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",value:(_vm.appStatus === 1 ? 'FILES PENDING TO SEND' : 'FILES'),expression:"appStatus === 1 ? 'FILES PENDING TO SEND' : 'FILES'",modifiers:{"hover":true,"v-primary":true}}],staticClass:"rounded-circle alert-files cursor-pointer",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.openVerificationModal(data.item)}}},[_vm._v(" "+_vm._s(data.item.files_counter)+" ")]):_vm._e()],1)]}},{key:"cell(status)",fn:function(data){return [(_vm.inSpecialist && _vm.currentUser.role_id !== 8)?_c('span',{class:[_vm.StatusColorText(data.item)]},[(data.item.app_status == 4)?_c('span',[_vm._v("EXPIRED")]):_c('span',[_vm._v(_vm._s(data.item.status))])]):_vm._e(),(!_vm.inSpecialist || _vm.currentUser.role_id === 8)?_c('status-app',{attrs:{"data":data,"options":_vm.statusOptions},on:{"addCharge":function($event){return _vm.openAddCharge(data.item)},"refreshTable":_vm.refresh}}):_vm._e()]}},{key:"cell(type_description)",fn:function(ref){
                  var item = ref.item;
return [_c('div',{class:[item.comment != null ? 'text-primary cursor-pointer' : ''],on:{"click":function($event){return _vm.showComment(item)}}},[_vm._v(" "+_vm._s(item.type_description)+" ")])]}},{key:"cell(client)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[_c('IconStatusAccount',{attrs:{"status":item.status_account_id,"fromBc":""}}),_c('router-link',{attrs:{"target":"_blank","to":{
                name: _vm.clientDashboardRouteName,
                params: {
                  idClient: ("" + (item.account_id)),
                },
              }}},[_vm._v(" "+_vm._s(item.client_name)+" ")])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"d-block",staticStyle:{"margin-left":"18px"}},[_vm._v(" "+_vm._s(item.client_account)+" ")])]),(item.program_id === 3)?_c('b-badge',{staticClass:"font10 ml-1 text-uppercase",attrs:{"variant":item.client_type_id == 2 ? 'light-primary' : 'light-info'}},[_vm._v(" "+_vm._s(item.client_type_id == 2 ? "Digital" : "Regular")+" ")]):_vm._e()]}},{key:"cell(name_advisor)",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name_advisor)+" ")])]}},{key:"cell(recommended_by)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(item.recommended_by))]),_c('span',[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(item.created_at)))])])]}},{key:"cell(module)",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"text-info"},[_vm._v(_vm._s(item.module))])]}},{key:"cell(bank_info)",fn:function(){return [_c('center',[_c('feather-icon',{staticClass:"active",attrs:{"icon":"CreditCardIcon","size":"20"}})],1)]},proxy:true},{key:"cell(amount)",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatMoney")(item.amount)))])]}},{key:"cell(potencial)",fn:function(ref){
              var item = ref.item;
return [(item.is_potencial === 'PENDING')?_c('b-button',{on:{"click":function($event){$event.preventDefault();return _vm.addPotencial(item)}}},[_vm._v("go")]):[(item.is_potencial === 'YES')?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(item.is_potencial)+" ")]):_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(item.is_potencial)+" ")])]]}},{key:"cell(tracking)",fn:function(ref){
              var item = ref.item;
return [_c('feather-icon',{staticClass:"cursor-pointer text-info",attrs:{"icon":"ListIcon","size":"20"},on:{"click":function($event){return _vm.openTrackingModal(item)}}})]}},{key:"cell(actions)",fn:function(ref){
              var item = ref.item;
return [(_vm.inSpecialist && item.status == 'PENDING' && item.status_paid == null)?_c('feather-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"TrashIcon","size":"20"},on:{"click":function($event){return _vm.removeRegister(item)}}}):_vm._e()]}},(_vm.appStatus == 2)?{key:"custom-foot",fn:function(){return [_c('b-tr',{staticClass:"bg-dark fixed-footer"},[_c('b-th',{attrs:{"colspan":_vm.indexAmount}}),_c('b-th',[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"font-size":"16px"}},[_c('b-badge',{staticClass:"rounded-pill mb-1 ml-auto",attrs:{"variant":"info"}},[_vm._v("Sub Total: $ "+_vm._s(_vm._f("currency")(_vm.amountSubTotal)))]),_c('b-badge',{staticClass:"rounded-pill ml-auto",attrs:{"variant":"info"}},[_vm._v("Total: $ "+_vm._s(_vm._f("currency")(_vm.amountTotal)))])],1)]),_c('b-th',{attrs:{"colspan":"11"}})],1)]},proxy:true}:null],null,true)})]},proxy:true}])}),(_vm.showForm)?_c('FormModal',{attrs:{"clientData":_vm.clientData,"otherServices":_vm.isOtherServices},on:{"closeModal":_vm.closeFormModal}}):_vm._e(),(_vm.showTracking)?_c('TrackingModal',{attrs:{"clientData":_vm.clientData},on:{"closeModal":_vm.closeTrackingModal}}):_vm._e(),(_vm.showAddCharge)?_c('add-charge-others-modal',{attrs:{"action":1,"data":_vm.dataClient,"allPaymentMethods":false,"sendFrom":"other_services_financial"},on:{"close":_vm.closeAddCharge,"refreshTable":_vm.refresh}}):_vm._e(),(_vm.ActiveVerificationModal)?_c('verification-modal',{attrs:{"appointment_id":String(_vm.idApp),"statusService":_vm.statusService,"program":11,"type_charge":_vm.type_charge},on:{"close":_vm.closeVerificationModal}}):_vm._e(),_c('b-modal',{attrs:{"centered":"","title":"Comment","hide-footer":""},model:{value:(_vm.showCommentModal),callback:function ($$v) {_vm.showCommentModal=$$v},expression:"showCommentModal"}},[_vm._v(" "+_vm._s(_vm.commentSelected)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
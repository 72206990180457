<template>
  <div>
    <filter-slot
      :filter="FiltersForSlot.filters"
      :total-rows="FiltersForSlot.totalRows"
      :paginate="FiltersForSlot.paginate"
      :start-page="FiltersForSlot.startPage"
      :to-page="FiltersForSlot.toPage"
      :filter-principal="FiltersForSlot.filterPrincipal"
      @reload="$refs['AppointmentTable'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <template #table>
        <b-table
          ref="AppointmentTable"
          slot="table"
          class="blue-scrollbar"
          :items="myProvider"
          :fields="visibleFields"
          :busy.sync="isBusy"
          show-empty
          no-provider-filtering
          :per-page="FiltersForSlot.paginate.perPage"
          :current-page="FiltersForSlot.paginate.currentPage"
          :foot-clone="false"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(responsible)="data">
            <center>
              <b-badge
                id="span_status"
                :class="StatusColorBackground(data.value)"
                pill
              >
                <span v-if="data.item.responsible == 1 && moduleId == 25"
                  >IN AGENT</span
                >
                <span v-else> {{ data.item.responsible }} </span>
              </b-badge>
            </center>
          </template>
          <template #cell(minimum_amount)="{ item }">
            {{ item.minimum_price | formatMoney }}
          </template>
          <template #cell(paid)="{ item }">
            <div
              :class="{
                'text-success': item.status_paid == 'YES',
                'text-warning': item.status_paid == 'PENDING',
                'text-danger': item.status_paid == 'NO',
              }"
            >
              {{ statusPaid(item) }}
            </div>
          </template>
          <template #cell(files)="data">
            <div class="position-relative">
              <feather-icon
                class="cursor-pointer"
                :class="{
                  'text-secondary': data.item.status_paid != 'YES',
                  'text-primary':
                    data.item.status_paid == 'YES' &&
                    data.item.files_counter > 0,
                }"
                icon="PaperclipIcon"
                size="20"
                :style="{
                  'pointer-events':
                    data.item.status_paid == 'YES' ? 'auto' : 'none',
                }"
                @click="
                  data.item.status_paid == 'YES' &&
                    openVerificationModal(data.item)
                "
              />
              <b-badge
                @click="openVerificationModal(data.item)"
                variant="primary"
                class="rounded-circle alert-files cursor-pointer"
                v-if="data.item.files_counter"
                v-b-tooltip.hover.v-primary="appStatus === 1 ? 'FILES PENDING TO SEND' : 'FILES'"
              >
                {{ data.item.files_counter }}
              </b-badge>
            </div>
          </template>
          <template #cell(status)="data">
            <span
              v-if="inSpecialist && currentUser.role_id !== 8"
              :class="[StatusColorText(data.item)]"
            >
              <span v-if="data.item.app_status == 4">EXPIRED</span>
              <span v-else>{{ data.item.status }}</span>
            </span>
            <status-app
              v-if="!inSpecialist || currentUser.role_id === 8"
              :data="data"
              :options="statusOptions"
              @addCharge="openAddCharge(data.item)"
              @refreshTable="refresh"
            />
          </template>
          <template #cell(type_description)="{ item }">
            <div 
              :class="[item.comment != null ? 'text-primary cursor-pointer' : '']"
              @click="showComment(item)"
            >
              {{ item.type_description }}
            </div>
          </template>
          <template #cell(client)="{ item }">
            <span class="d-flex align-items-center" style="gap: 5px">
              <IconStatusAccount :status="item.status_account_id" fromBc />
              <router-link
                target="_blank"
                :to="{
                  name: clientDashboardRouteName,
                  params: {
                    idClient: `${item.account_id}`,
                  },
                }"
              >
                {{ item.client_name }}
              </router-link>
            </span>
            <div class="d-flex align-items-center">
              <span class="d-block" style="margin-left: 18px">
                {{ item.client_account }}
              </span>
            </div>
            <b-badge
              class="font10 ml-1 text-uppercase"
              :variant="
                item.client_type_id == 2 ? 'light-primary' : 'light-info'
              "
              v-if="item.program_id === 3"
            >
              {{ item.client_type_id == 2 ? "Digital" : "Regular" }}
            </b-badge>
          </template>

          <template #cell(name_advisor)="{ item }">
            <span>{{ item.name_advisor }} </span>
          </template>

          <template #cell(recommended_by)="{ item }">
            <div class="d-flex flex-column">
              <span>{{ item.recommended_by }}</span>
              <span>{{ item.created_at | myGlobalWithHour }}</span>
            </div>
          </template>

          <template #cell(module)="{ item }">
            <span class="text-info">{{ item.module }}</span>
          </template>

          <template #cell(bank_info)="">
            <center>
              <feather-icon class="active" icon="CreditCardIcon" size="20" />
            </center>
          </template>
          <template #cell(amount)="{ item }">
            <span>{{ item.amount | formatMoney }}</span>
          </template>
          <template #cell(potencial)="{ item }">
            <b-button
              v-if="item.is_potencial === 'PENDING'"
              @click.prevent="addPotencial(item)"
              >go</b-button
            >
            <template v-else>
              <b-badge variant="success" v-if="item.is_potencial === 'YES'">
                {{ item.is_potencial }}
              </b-badge>
              <b-badge variant="warning" v-else>
                {{ item.is_potencial }}
              </b-badge>
            </template>
          </template>
          <template #cell(tracking)="{ item }">
            <feather-icon
              class="cursor-pointer text-info"
              icon="ListIcon"
              size="20"
              @click="openTrackingModal(item)"
            />
          </template>

          <template #cell(actions)="{ item }">
              <feather-icon
                v-if="inSpecialist && item.status == 'PENDING' && item.status_paid == null"
                class="cursor-pointer text-danger"
                icon="TrashIcon"
                size="20"
                @click="removeRegister(item)"
              />
          </template>

          <template v-if="appStatus == 2" #custom-foot>
            <b-tr class="bg-dark fixed-footer">
              <b-th :colspan="indexAmount" />
              <b-th>
                <div class="d-flex flex-column" style="font-size: 16px">
                  <b-badge class="rounded-pill mb-1 ml-auto" variant="info"
                    >Sub Total: $ {{ amountSubTotal | currency }}</b-badge
                  >
                  <b-badge class="rounded-pill ml-auto" variant="info"
                    >Total: $ {{ amountTotal | currency }}</b-badge
                  >
                </div>
              </b-th>
              <b-th colspan="11"></b-th>
            </b-tr>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <FormModal
      v-if="showForm"
      @closeModal="closeFormModal"
      :clientData="clientData"
      :otherServices="isOtherServices"
    />
    <TrackingModal
      v-if="showTracking"
      @closeModal="closeTrackingModal"
      :clientData="clientData"
    />

    <add-charge-others-modal
      v-if="showAddCharge"
      :action="1"
      @close="closeAddCharge"
      :data="dataClient"
      @refreshTable="refresh"
      :allPaymentMethods="false"
      sendFrom="other_services_financial"
    />
    <verification-modal
      v-if="ActiveVerificationModal"
      :appointment_id="String(idApp)"
      :statusService="statusService"
      :program="11"
      :type_charge="type_charge"
      @close="closeVerificationModal"
    />
    <b-modal centered v-model="showCommentModal" title="Comment" hide-footer>
      {{ commentSelected }}
    </b-modal>
  </div>
</template>

<script>
// DATA
import Fields from "@/views/commons/components/other-services/data/fields";
import Filters from "@/views/commons/components/other-services/data/filters";

// COMPONENTS
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";
import TrackingModal from "@/views/commons/components/other-services/view/component/modal/TrackingModal.vue";
import StatusApp from "@/views/commons/components/other-services/view/component/modal/StatusAppNew.vue";
import AddChargeOthersModal from "@/views/commons/components/other-services/view/component/modal/AddChargeModal.vue";
import VerificationModal from "@/views/commons/components/other-services/view/component/modal/VerificationModal.vue";
import ChangeStatusP from "@/views/commons/components/other-services/view/component/ChangeStatusP.vue";
import ModalActionPaid from "@/views/commons/components/other-services/view/component/modal/ModalActionPaid.vue";
// SERVICES
import AppointmentService from "@/views/commons/components/other-services/service/appointment.service";

// VUEX
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    IconStatusAccount,
    TrackingModal,
    StatusApp,
    VerificationModal,
    AddChargeOthersModal,
    ChangeStatusP,
    ModalActionPaid,
  },
  data() {
    return {
      Fields,
      items: [],
      FiltersForSlot: {
        filters: Filters,
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name...",
          model: "",
        },
      },
      isBusy: false,
      showForm: false,
      showTracking: false,
      clientData: null,
      statusOptions: [
        { value: 1, text: "ACCEPTED" },
        { value: 2, text: "REJECTED" },
      ],
      activeModal: false,
      showAddCharge: false,
      dataClient: {},
      app_status: 1,
      idApp: "",
      ActiveVerificationModal: false,
      statusService: null,
      table: {
        fields: Fields,
        isBusy: false,
        optionsStatus: ["", "ACCEPTED", "REJECTED"],
        optionsStatusResult: ["PENDING", "APPROVED", "DENIED"],
        optionsStatusPaid: ["", "YES", "NO"],
      },
      amountT: 0,
      routesModules: {
        5: "debt-solution-other-services",
        6: "credit-experts-other-services",
        7: "boost-credit-other-services",
        20: "connection-other-services",
        22: "customerservice-other-services",
        29: "other-services-financial-approval",
      },
      type_charge: null,
      showCommentModal: false,
      commentSelected: null,
    };
  },
  mounted() {
    this.hideFilters();
  },
  computed: {
    ...mapGetters({
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
      currentUser: "auth/currentUser",
      agentsConnection: "DebtSolutionApplications/G_AGENTS_CONNECTION",
    }),
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },

    isOtherServices() {
      return this.$route.meta.pageTitle == "Other Services";
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    visibleFields() {
      this.modifyVisibility("status", !(this.$route.meta.statusApp == 2));
      this.modifyVisibility("responsible", this.$route.meta.statusApp == 1);
      this.modifyVisibility("actions", this.$route.meta.statusApp == 1);

      return this.Fields.filter((field) => field.visible);
    },
    currentTab() {
      let obj = {
        1: "IN PROCESS",
        2: "COMPLETED",
      };
      return obj[this.$route.matched[2].meta.status];
    },
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
    isFinancialApproval() {
      return this.moduleId == 29;
    },
    inSpecialist() {
      return [11, 25, 28, 29, 30].includes(this.moduleId);
    },
    amountSubTotal() {
      let suma = 0;
      this.items.map((item) => {
        // console.log(item.amount)
        if (item.amount !== null) suma += parseFloat(item.amount);
      });
      return suma;
    },
    amountTotal() {
      return parseFloat(this.amountT);
    },
    appStatus() {
      return this.$route.meta.statusApp;
    },
    indexAmount(){
      return this.Fields.filter(item => item.visible).findIndex(item => item.key == 'amount');
    }
  },
  methods: {
    ...mapMutations({
      M_PENDING_OTHER_SERVICES: "OtherServices/M_PENDING_OTHER_SERVICES",
    }),
    ...mapActions({
      A_GET_PENDING_OTHER_SERVICES:
        "OtherServices/A_GET_PENDING_OTHER_SERVICES",
    }),
    openModalAction() {
      this.activeModal = true;
    },

    async myProvider(ctx) {
      try {
        const params = {
          page: ctx.currentPage,
          per_page: ctx.perPage,
          name_text: this.FiltersForSlot.filterPrincipal.model,
          module_id: this.isFinancialApproval
            ? this.getFilterByKey("department").model
            : this.moduleId,
          from: this.getFilterByKey("from").model,
          to: this.getFilterByKey("to").model,
          type: this.getFilterByKey("type").model,
          status: this.getFilterByKey("status").model,
          other_services: this.isOtherServices,
          app_status: this.$route.meta.statusApp,
          user_id: ![1, 2, 17, 25].includes(this.currentUser.role_id)
            ? this.currentUser.user_id
            : null,
        };
        const data = await AppointmentService.searchOtherServices(params);
        this.FiltersForSlot.startPage = data.from;
        this.FiltersForSlot.paginate.currentPage = data.current_page;
        this.FiltersForSlot.paginate.perPage = data.per_page;
        this.FiltersForSlot.totalRows = data.total;
        this.FiltersForSlot.toPage = data.to;
        this.items = data.data;

        this.amountT = data.data.length > 0 ? data.data[0].total_amount : 0;
        // this.refreshCountInSidebar();

        return data.data || [];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    modifyVisibility(nameKey, isVisible) {
      let keyField = this.Fields.findIndex((item) => item.key == nameKey);
      if (keyField != -1) {
        this.Fields[keyField].visible = isVisible;
      }
    },
    async updateStatus(data) {
      try {
        this.addPreloader();
        const { n_option, n_action } = data;
        if (n_option === "StatusPaid") {
          if (n_action === "NO" || n_action === "PENDING") {
            data.status = n_action;
            data.statusService;
            await AppointmentService.updateStatusPaid(data);
            this.refresh();
          } else {
            this.openAddCharge(data);
          }
        } else {
          await AppointmentService.updateStatus(data);
          this.refresh();
        }
      } catch (ex) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },
    disabledFile(value) {
      return !value;
    },

    filterColumn(key, bool) {
      let field = this.Fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    refresh() {
      this.showAddCharge = false;
      this.$refs.AppointmentTable.refresh();
      this.refreshCountInSidebar();
    },
    classIcon(item) {
      return item.status === "ACCEPTED"
        ? !item.status_paid
          ? this.inSpecialist
            ? "text-primary"
            : "text-primary oscillate cursor-pointer"
          : item.status_paid === "PENDING"
          ? "text-warning"
          : "text-success"
        : "text-secondary";
    },
    tooltipMessage(item) {
      if (item.status == "ACCEPTED") {
        switch (item.status_paid) {
          case "YES":
            return "Paid";
          case "NO":
            return "Not paid";
          case "PENDING":
            return "Pending approval";
          default:
            return "Pending pay";
        }
      } else {
        return "Pending pay";
      }
    },
    getFilterByKey(key) {
      const filter = this.FiltersForSlot.filters.find(
        (filter) => filter.key === `${key}`
      );

      return filter;
    },
    hideFilters() {
      let moduleId = this.$route.matched[0].meta.module;
      if (moduleId != 29) {
        let keyDepartment = this.FiltersForSlot.filters.findIndex(
          (item) => item.key == "department"
        );
        if (keyDepartment != -1) {
          this.FiltersForSlot.filters[keyDepartment].visible = false;
        }
        this.FiltersForSlot.filters = this.FiltersForSlot.filters.filter(
          (item) => item.visible
        );
      }
    },
    resetAllFilters() {
      this.FiltersForSlot.filters.forEach((filter) => {
        filter.model = null;
      });
      this.FiltersForSlot.filterPrincipal.model = null;
      this.$refs.AppointmentTable.refresh();
    },
    openFormModal(data) {
      this.showForm = true;
      this.clientData = data;
    },

    openVerificationModal({ id, app_status, type_id }) {
      this.idApp = id;
      this.statusService = app_status;
      this.ActiveVerificationModal = true;
      this.type_charge = type_id;
    },
    closeVerificationModal(value) {
      if (value) {
        this.$refs.AppointmentTable.refresh();
      }
      this.ActiveVerificationModal = false;
    },
    showComment(item){
      this.commentSelected = item.comment;
      this.showCommentModal = item.comment != null;
    },
    closeFormModal() {
      this.showForm = false;
    },
    openTrackingModal(data) {
      this.showTracking = true;
      this.clientData = data;
    },
    closeTrackingModal() {
      this.showTracking = false;
    },
    openAddCharge(data) {
      this.dataClient = data;
      this.showAddCharge = true;
    },

    closeAddCharge() {
      this.showAddCharge = false;
    },
    async addPotencial(item) {
      try {
        let confirmed = "NO";
        const valid = await this.showQuestionSwal();
        if (!valid.dismiss || valid.dismiss == "cancel") {
          if (valid.isConfirmed) {
            confirmed = "YES";
          }
          const params = {
            id: item.id,
            confirmed,
            module_id: this.moduleId,
            user_id: this.currentUser.user_id,
          };

          const data = await AppointmentService.updateReprogrammingAppointment(
            params
          );
          this.refresh();
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    async removeRegister(item) {
      const params = {
        id: item.id,
        user_id: this.currentUser.user_id,
      };
      try {
        const confirm = await this.showConfirmSwal();
        if (!confirm.isConfirmed) return;

        this.addPreloader();
        const data = await AppointmentService.removeRegister(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Removed successfully"
          );
          this.$refs.AppointmentTable.refresh();
          await this.refreshCountInSidebar();
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "CXIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    statusPaid(item) {
      return item.status_paid == "PENDING" ? "VERIFYING" : item.status_paid;
    },
    StatusColorText({ status, app_status }) {
      if(app_status == 4) return "text-danger";

      switch (status) {
        case "PENDING":
          return "text-warning";
        case "ACCEPTED":
          return "text-success";
        case "REJECTED":
          return "text-danger";
        default:
          return "bg-secondary text-light";
      }
    },
    StatusColorBackground(status) {
      switch (status) {
        case "IN ADVISOR":
          return ["bg-light-warning"];
        case "IN SPECIALIST":
          return ["bg-light-primary"];
        case 3:
          return ["bg-light-success"];
        default:
          return "bg-secondary text-light";
      }
    },
    async refreshCountInSidebar() {
      await this.A_GET_PENDING_OTHER_SERVICES();
    },
  },

  watch: {
    getRefreshTable(value) {
      if (value) {
        this.$refs["AppointmentTable"].refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },
};
</script>

<style scoped>
#span_status {
  padding: 5px 10px;
  border-radius: 15px;
  width: 150px;
}
.green {
  background: var(--success);
  color: #f2f2f2;
}
.red {
  background: var(--danger);
  color: #f2f2f2;
}
.yellow {
  background: var(--light-warning);
  color: #f2f2f2;
}
.blue {
  background: #155db1;
  color: #f2f2f2;
}
.active {
  color: var(--success);
  cursor: pointer;
}
.inactive {
  color: var(--secondary);
  cursor: pointer;
}

@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
span.item-automatic {
  background-color: #89375f !important;
  border-color: #89375f !important;
}
span.item-manual {
  background-color: #009fbd !important;
  border-color: #009fbd !important;
}
.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.alert-files {
  position: absolute;
  top: -10px;
}
</style>

export default [
  {
    key: 'client',
    label: 'Client',
    class: 'text-left',
    visible: true,
  },
  {
    key: 'name_advisor',
    label: 'Advisor',
    class: 'text-center',
    visible: true,
  },
  {
    key: "recommended_by",
    label: "Recommended by",
    class: "text-center px-1",
    visible: true,
  },
  {
    key: 'type_description',
    label: 'Type',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'minimum_amount',
    label: 'Minimum amount',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'status',
    label: "Status",
    class: "text-center",
    visible: true
  },
  {
    key: 'paid',
    label: 'Paid',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'amount',
    label: 'amount',
    thClass: 'text-right pr-2',
    tdClass: 'text-right pr-2',
    thStyle: {
      width: "8%"
    },
    visible: true,
  },

  {
    key: 'responsible',
    label: 'Responsible',
    class: 'text-center',
    visible: true,
  },

  {
    key: 'files',
    label: 'File',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'tracking',
    label: 'Tracking',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'actions',
    label: 'Actions',
    class: 'text-center',
    visible: true
  }
];

<template>
  <div>
    <template v-if="inSpecialist">
      <span :class="[StatusColorText(item[nameProp])]">{{
        item[nameProp]
      }}</span>
    </template>    
    <template v-else>
      <div v-if="item[nameProp] == null">
        <div v-if="!statusPending.value">
          <span :class="[StatusColorText(item[nameProp])]"> PENDING</span>
          <feather-icon          
            icon="EditIcon"
            class="text-warning cursor pointer"
            v-b-tooltip.hover.right="'Edit'"
            style="margin-left: 5px"
            size="15"
            @click="changeStatusPending(item)"
          />
        </div>
        <div v-else-if="statusPending.value">
          <b-input-group size="sm">
            <b-form-select v-model="status" @change="openModalAction">
              <b-form-select-option
                :value="item"
                v-for="(item, index) in options"
                :key="index"
              >
                {{ item }}
              </b-form-select-option>
            </b-form-select>
            <b-input-group-append is-text @click="hideSelector()">
              <feather-icon
                icon="XIcon"                
                class="text-danger cursor-pointer"
                size="20"
              />
            </b-input-group-append>
          </b-input-group>     
        </div>
      </div>
      <div v-else>
        <span :class="[StatusColorText(item[nameProp])]">
          {{statusName(item[nameProp])}}</span>
      </div>    
    </template>
    <modal-action-paid
      :data="item"
      v-if="modalActionsView"
      @close="openModalAction"
      :action="status"
      :option="option"
      @send="(value) => $emit('send', value)"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalActionPaid from "@/views/commons/components/other-services/view/component/modal/ModalActionPaid.vue";
export default {
  name: "ChangeStatusP",
  props: {
    item: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    nameProp: {
      type: String,
      required: true,
    },
    option: {
      type: String,
      required: true,
    },
  },
  components: {
    ModalActionPaid,
  },
  data() {
    return {
      statusPending: {
        id: 0,
        value: false,
      },
      status: "",
      modalActionsView: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    inSpecialist() {
      const modules = [11, 25, 28, 29, 30];
      return (
        modules.includes(this.moduleId) &&
        (this.item[this.nameProp] == "PENDING" ||
          this.item[this.nameProp] === null)
      );
    },
  },
  methods: {
    StatusColorText(status) {
      switch (status) {
        case "PENDING":
          return "text-warning";
        case "APPROVED":
        case "YES":
          return "text-success";
        case "DENIED":
        case "NO":
          return "text-danger";
        default:
          return "text-warning";
      }
    },
    statusName(status){
      switch (status) {
        case "PENDING":
          return "VERIFYING";
        case "YES":
          return "YES";
        case "NO":
          return "NO";
        default:
          return null;
      }
    },
    hideSelector(){
      this.status = null;
      this.statusPending.value = false;
    },
    changeStatusPending(item) {
      if (!item[this.nameProp] || item[this.nameProp] == "PENDING") {
        this.statusPending.value = !this.statusPending.value;
        this.statusPending.id = item.id;
        this.status = "";
      }
    },
    openModalAction() {
      if (this.option == "StatusPaid" && this.status === "YES") {
        this.$emit("send", {
          ...this.item,
          n_action: "YES",
          n_option: "StatusPaid",
        });
      } else {
        if (this.status != "") {
          this.modalActionsView = !this.modalActionsView;
        }
      }
    },
  },
};
</script>